export const reportData = {
  reportOptions: [],
  reportValue: "",
  valueEdit: "",
  fileName: "",
  fileType: 1,
  showCodeSearch: false,
  disabled: false,
  showDrafr: false,
  codeList: [],
  pageParame: [],
  itemParame: {},
  codes: [],
  moreFile: [],
  show: false,
};
